<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item v-if="scope === 'GSA'">
        <a-select :disabled="whileSaving || mode === 'edit'" v-decorator="['businessId', {
              rules: [
                { required: true, message: 'Please input Business!' },
              ],
            },]" size="large" placeholder="Business*">
          <a-select-option :value="item.businessId" v-for="item in businesses" v-bind:key="item.businessId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="scope !== 'LA' || mode === 'edit'">
        <span style="float:right;color:#5168ea;cursor:pointer;" @click="selectAllLocations">Select All</span>
        <a-select mode="multiple" :disabled="whileSaving" v-decorator="['locationId', {
              rules: [
                { required: true, message: 'Please input Location!' },
              ],
            },]" size="large" placeholder="Location*">
          <a-select-option :value="item.locationId" v-for="item in getLocations()" v-bind:key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving || mode === 'edit'"
          size="large"
          v-decorator="[
            'screen2passId',
            {
              rules: [
                { required: true, message: 'Please input User Id!' },
              ],
            },
          ]"
          placeholder="User Id*"
        >
        </a-input>
      </a-form-item>
      <a-row>
          <a-col :span="12">
            <a-form-item>
              <a-input
                :disabled="whileSaving"
                v-decorator="['firstName', {
                    rules: [
                      { required: true, message: 'Please input First Name!' },
                    ],
                  },]"
                size="large"
                placeholder="First Name*"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="11" :offset="1">
            <a-form-item>
              <a-input
                :disabled="whileSaving"
                v-decorator="['lastName', {
                    rules: [
                      { required: true, message: 'Please input Last Name!' },
                    ],
                  },]"
                size="large"
                placeholder="Last Name*"
              >
              </a-input>
            </a-form-item>
          </a-col>
      </a-row>
      <a-form-item>
        <a-select :disabled="whileSaving" v-decorator="['gender', {
              rules: [
                { required: true, message: 'Please input Gender!' },
              ],
            },]" default-value="Male" size="large" placeholder="Gender*">
          <a-select-option value="Male">
            Male
          </a-select-option>
          <a-select-option value="Female">
            Female
          </a-select-option>
          <a-select-option value="Other">
            Other
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['email']"
          size="large"
          placeholder="Email"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['contactNumber']"
          size="large"
          placeholder="Contact Number"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-date-picker
          :disabled="whileSaving"
          size="large"
          style="width: 100%"
          v-decorator="[
            'dob',
          ]"
          placeholder="Date Of Birth"
        >
        </a-date-picker>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          size="large"
          v-decorator="[
            'address',
          ]"
          placeholder="Steet Address"
        >
        </a-input>
      </a-form-item>
      <a-row>
          <a-col :span="8">
              <a-form-item>
                  <a-input
                      :disabled="whileSaving"
                      size="large"
                      v-decorator="['city']"
                      placeholder="City"
                  >
                  </a-input>
              </a-form-item>
          </a-col>
          <a-col :span="7" :offset="1">
              <a-form-item>
                  <a-input
                      :disabled="whileSaving"
                      v-decorator="['state']"
                      size="large"
                      placeholder="State"
                  >
                  </a-input>
              </a-form-item>
          </a-col>
          <a-col :span="7" :offset="1">
              <a-form-item>
                  <a-input
                      :disabled="whileSaving"
                      v-decorator="['zipcode']"
                      size="large"
                      placeholder="Zip Code"
                  >
                  </a-input>
              </a-form-item>
          </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item>
            <a-input
              :disabled="whileSaving"
              v-decorator="['referenceId1']"
              size="large"
              placeholder="Reference Id1"
            >
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="11" :offset="1">
          <a-form-item>
            <a-input
              :readonly=true
              v-decorator="['referenceId2']"
              size="large"
              placeholder="Reference Id2"
            >
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          class="login-form-button"
          style="width: 100%; background: #5168ea; border-color: #5168ea"
        >
          <a-icon type="lock" style="color: white" />
          <span style="color: white" v-if="whileSaving && mode==='add'">Saving...</span>
          <span style="color: white" v-if="!whileSaving && mode==='add'">Save Known User</span>
          <span v-if="whileSaving && mode==='edit'" style="color: white">Updating...</span>
          <span v-if="!whileSaving && mode==='edit'" style="color: white">Update Known User</span>
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { SubscriberApi } from "../../services";

export default {
  props: {
    businesses: Array,
    locations: Array,
    scope: String,
    mode: String,
    item: Object
  },
  data(){
    return {
      whileSaving: false,
      profile: JSON.parse(localStorage.getItem("profile") || "{}")
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
    this.form.resetFields();
  },
  async mounted() {
    this.form.resetFields();
    if (this.mode === 'edit') {
      this.form.setFieldsValue({
        ...this.item
      });
      this.setLocations();
    }
  },
  watch: {
    item() {
      this.form.resetFields();
      if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
        this.setLocations();
      }
    },
    mode(nv, ov) {
      this.form.resetFields();
      if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
        this.setLocations();
      }
    }
  },
  methods: {
    async setLocations() {
      if (this.mode === 'edit') {
        const response = await SubscriberApi.locations(this.item.subscriberId);
        const locations = response.data.map(record => record.locationId);
        this.form.setFieldsValue({
          locationId: locations
        });
      }
    },
    getLocations() {
      const businessId = this.form.getFieldValue('businessId');
      if (!businessId) {
        return this.locations;
      }
      return this.locations.filter(record => record.businessId === businessId);
    },
    selectAllLocations() {
      const _locations = this.getLocations();
      const locations = _locations.map(record => record.locationId);
      this.form.setFieldsValue({
        locationId: locations
      });
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.whileSaving = true;
            if (this.mode === 'add') {
              await SubscriberApi.create(values);
            } else {
              values.subscriberId = this.item.subscriberId;
              await SubscriberApi.update(values);
            }
            this.whileSaving = false;
            this.form.resetFields();
            if (this.mode === 'add') {
              this.$message.success(
                "Known User created successfully."
              );
            } else {
              this.$message.success(
                "Known User updated successfully."
              );
            }
            this.$emit('success', true);
          } catch (err) {
            this.whileSaving = false;
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$error.message.error(
                "Something went wrong. Please contact administrator."
              );
            }
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>
<style scoped>

a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>