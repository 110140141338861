











































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const SubscriberModule = namespace("SubscriberModule");
const LocationModule = namespace("LocationModule");
const BusinessModule = namespace("BusinessModule");
import { Subscriber } from "../../store/modules/subscriber/types";
import { Location } from "../../store/modules/location/types";
import { Business } from "../../store/modules/business/types";
import Icon from "../../components/Icon.vue";
import SubscriberAdd from './SubscriberAdd.vue';
import { sortStringAttributes } from '../../services';

@Component({
  components: {
    Icon,
    SubscriberAdd
  }
})
export default class SubscriberList extends Vue {
  @SubscriberModule.Getter
  public subscribers: Subscriber[];

  @LocationModule.Getter
  public locations: Location[];

  @BusinessModule.Getter
  public businesses: Business[];

  public profile = JSON.parse(localStorage.getItem('profile') || '{}') || {}
  public searchTerm = "";
  public visible = false;
  public fetching = false;
  public activeMode = "add";
  public activeItem:any = {};

  public columns = [
    {
      title: "User Id",
      dataIndex: "screen2passId",
      sorter: (a, b) => sortStringAttributes(a.screen2passId, b.screen2passId),
    },
    {
      title: "Full Name",
      scopedSlots: { customRender: 'fullName' },
      slots: { title: "nameAndAddress" },
      sorter: (a, b) => sortStringAttributes(a.firstName, b.firstName),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sortStringAttributes(a.email, b.email),
    },
    {
      title: "Contact No",
      dataIndex: "contactNumber",
      sorter: (a, b) => sortStringAttributes(a.contactNumber, b.contactNumber),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => sortStringAttributes(a.state, b.state),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      scopedSlots: { customRender: 'dob' },
      slots: { title: "dob" },
      sorter: (a, b) => sortStringAttributes(a.dob, b.dob),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => sortStringAttributes(a.gender, b.gender),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => sortStringAttributes(a.updatedAt, b.updatedAt),
      slots: { title: "updatedAt" },
      key: 'updatedAt',
      scopedSlots: { customRender: 'updatedAt' },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: 'Action' },
      fixed: 'right',
      width: 150
    }
  ];
  
  @SubscriberModule.Action
  public load!: () => Promise<Subscriber[]>;

  @SubscriberModule.Action
  public reset!: () => Promise<void>;

  @SubscriberModule.Action
  public disableSubscriber!: (subscriberId:string) => Promise<void>;

  @SubscriberModule.Action
  public search!: (term:string) => Promise<Subscriber[]>;

  @LocationModule.Action
  public loadAllLocations!: () => Promise<Location[]>;

  @BusinessModule.Action
  public loadBusinesses: () => Promise<Business[]>;

  get modalTitle() {
    return this.activeMode === 'add' ? 'Add Known User' : 'Edit Known User';
  }

  async beforeDestroy() {
    this.reset();
  }

  getBusinessName(businessId:string) {
    const response = this.businesses.filter(record => record.businessId === businessId)
    if (response.length > 0) return response[0].name;
    return '';
  }

  getLocationName(locationId:string) {
    const response = this.locations.filter(record => record.locationId === locationId)
    if (response.length > 0) return response[0].name;
    return '';
  }

  async confirmDisable(record) {
    await this.disableSubscriber(record.subscriberId);
    this.fetching = true;
    await this.load();
    this.fetching = false;
  }

  async mounted() {
    this.fetching = true;
    try {
      switch(this.profile.scope) {
        case 'GSA':
          this.columns.unshift({
            title: "Location",
            scopedSlots: { customRender: 'locationName' },
            slots: { title: "locationName" },
            sorter: (a, b) => sortStringAttributes(a.name, b.name),
          })
          this.columns.unshift({
            title: "Business",
            scopedSlots: { customRender: 'businessName' },
            slots: { title: "businessName" },
            sorter: (a, b) => sortStringAttributes(a.name, b.name),
          })
          break;
        case 'BA':
          this.columns.unshift({
            title: "Location",
            scopedSlots: { customRender: 'locationName' },
            slots: { title: "locationName" },
            sorter: (a, b) => sortStringAttributes(a.name, b.name),
          })
          break;
        default:
          break;
      }
      await this.load();
      try {
        await this.loadBusinesses();
      } catch(err) {
        console.log(err);
      }
      await this.loadAllLocations();
    } catch(err) {
      console.log(err);
    }
    this.fetching = false;
  }

  navigate(to) {
    this.$router.push(to);
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
  }

  subscriberAdded() {
    this.visible = false;
    this.load();
  }

  customRow(record) {
    return {
      on: {
        click: (event) => {
          this.$router.push(`/location/view/${record.locationId}`);
        },
      },
    };
  }
}
